import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import Layout from '../components/Layout'
import MetaImage from '../assets/images/benchmarks-meta.png'
import CTABlock from '../components/blocks/CTABlock'
import tag from '../assets/images/tags/benchmarks-tag.svg'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import Stack from '../components/primitives/Stack'
import { BenchmarksTable } from '../components/BenchmarksTable'
import Box from '../components/primitives/Box'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import Blurb from '../components/system/Blurb'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import { RowVariant } from '../components/ComparisonTable'
import { Link } from 'gatsby'
import Br from '../components/system/Br'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/benchmarks.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
      }
    }
    blurb1: file(relativePath: { eq: "images/product/overview/target.png" }) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb2: file(
      relativePath: { eq: "images/product/overview/anti-patterns.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    blurb3: file(
      relativePath: { eq: "images/product/overview/working-agreement.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

type BenchmarkExplanation = {
  key: string
  title: string
  tooltip?: string
  description: ReactNode
  rowVariant?: RowVariant
  isNested?: boolean
}

export const benchmarkExplanations = [
  {
    key: 'engineeringInvestment',
    title: 'Engineering investment',
    tooltip:
      'A sustainable engineering organization requires investment in non-product work.',
    description: (
      <>
        The distribution of engineering work, according to the{' '}
        <Link to="/blog/balancing-engineering-investments/">
          Balance Framework
        </Link>
      </>
    ),
  },
  {
    key: 'flowEfficiency',
    title: 'Flow efficiency',
    tooltip: 'Idle work suggests frequent interruptions and priority changes.',
    description:
      'The share of days an issue was actively worked on during its lifetime.',
    rowVariant: 'highlight',
  },
  {
    key: 'batchSize',
    title: 'Batch size',
    tooltip:
      'Smaller batches of work tend to move through the system faster and have less risk.',
    description: 'The number of lines in most changes.',
  },
  {
    key: 'leadTimeToChange',
    title: 'Lead time to change',
    tooltip:
      'Long lead times might be a symptom of overly large increments, frequent interruptions, or too much WIP.',
    description:
      'How long it takes for a task to go from start to production. This includes:',
    rowVariant: 'highlightStart',
  },
  {
    key: 'prCycletime',
    title: 'PR cycle time',
    tooltip:
      'Speeding up code reviews is often the most effective way to improve cycle time.',
    description: (
      <>
        How long it takes for an open PR to be merged. This includes:
        <br />
        <br />
        Coding time
        <br />
        Review time
        <br />
        Merge time
      </>
    ),
    rowVariant: 'highlightMiddle',
    isNested: true,
  },
  {
    key: 'deploymentFrequency',
    title: 'Deployment frequency',
    tooltip:
      'Frequent deploys encourage smaller changes and enable rapid iteration.',
    description: 'How often new code gets released to users.',
    rowVariant: 'highlightEnd',
    isNested: true,
  },
  {
    key: 'timeToDeploy',
    title: 'Time to deploy',
    tooltip:
      'Rapid deploys enable quicker incident mitigation and encourage smaller changes.',
    description:
      'How long it takes for most approved changes to reach production.',
  },
] as const satisfies readonly BenchmarkExplanation[]

export type ExplanationKey = (typeof benchmarkExplanations)[number]['key']

export type BenchmarkColumn = {
  title: string
  values: Record<ExplanationKey, ReactNode>
}

const benchmarksConfig: BenchmarkColumn[] = [
  {
    title: 'Great',
    values: {
      engineeringInvestment: (
        <>
          10% KTLO, <Br />
          15% productivity, <Br />
          60% on new things & <Br />
          improving things
        </>
      ),
      flowEfficiency: (
        <>
          &gt; 95% of in-progress <Br />
          time is active
        </>
      ),
      batchSize: '< 200 lines',
      leadTimeToChange: '< 24 hours',
      prCycletime: '< 24 hours',
      deploymentFrequency: 'Continuously',
      timeToDeploy: '< 15 minutes',
    },
  },
  {
    title: 'Good',
    values: {
      engineeringInvestment: (
        <>
          30% KTLO, <Br />
          10% productivity
        </>
      ),
      flowEfficiency: (
        <>
          &gt; 70% of in-progress <Br />
          time is active
        </>
      ),
      batchSize: ' < 500 lines',
      leadTimeToChange: '< 3 working days',
      prCycletime: '< 3 working days',
      deploymentFrequency: 'Daily',
      timeToDeploy: '< 30 minutes',
    },
  },
  {
    title: 'Needs attention',
    values: {
      engineeringInvestment: (
        <>
          50% KTLO, <Br />
          or &lt; 10% productivity
        </>
      ),
      flowEfficiency: (
        <>
          &lt; 70% of in-progress <Br />
          time is active
        </>
      ),
      batchSize: '> 500 lines',
      leadTimeToChange: '> 7 working days',
      prCycletime: '> 5 working days',
      deploymentFrequency: 'Less than daily',
      timeToDeploy: '> 1 hour',
    },
  },
]

const BenchmarksPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Software engineering benchmarks"
      variant="dark"
      isNew
      description="Learn what “good” looks like with Swarmia’s software engineering benchmarks."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title="Learn what “good” looks like with Swarmia’s engineering benchmarks"
        content="Your best benchmark is your own baseline. But if you’re interested in quickly identifying opportunities for improvement, start here."
        backgroundImage={getImage(data.backgroundImage)!}
        customCta={<></>}
      />
      <Box marginBottom={48}>
        <LeadBlock
          heading="Drive engineering effectiveness with sensible benchmarks"
          content="Use benchmarks from modern engineering organizations to understand where your organization stands compared to peers."
          link={{
            title: 'Learn how to use these benchmarks',
            href: '/blog/engineering-benchmarks/',
          }}
        />
      </Box>
      <Stack maxWidth={1400} marginX="auto" space={24}>
        <BenchmarksTable benchmarks={benchmarksConfig} />
      </Stack>
      <Box marginTop={48}>
        <CTABlock title="Find your engineering organization’s biggest opportunities for improvement." />
      </Box>
      <LeadBlock
        heading="Start your continuous improvement journey today"
        content="Understand your baseline, recognize concrete areas of improvement, and start systematically driving engineering effectiveness."
        link={{
          title: 'Watch our product tour',
          href: '/product-tour/',
        }}
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Establish your baseline"
            text="Focus your efforts on areas where you have the most room for improvement."
            image={getImage(data.blurb1)}
          />,
          <Blurb
            key="2"
            title="Eliminate teamwork anti-patterns"
            text="Identify and fix harmful behaviors like siloing or too much work in progress."
            image={getImage(data.blurb2)}
          />,
          <Blurb
            key="3"
            title="Put improvement on autopilot"
            text="Adopt relevant and achievable working agreements as a team."
            image={getImage(data.blurb3)}
          />,
        ]}
      />
      <QuoteBlock person="tim" />
      <CTABlock
        title={
          <>Get your baseline metrics by connecting&nbsp;to&nbsp;Swarmia.</>
        }
      />
      <FeaturedBlogArticles
        heading="More from the swarmia blog"
        articles={[
          '/blog/engineering-benchmarks/',
          '/blog/measuring-software-development-productivity/',
          '/blog/engineering-productivity-bottlenecks/',
        ]}
        newStyles
      />
    </Layout>
  )
}

export default BenchmarksPage
