import _ from 'lodash'
import React, { useState } from 'react'
import Select from './Select'
import infoIcon from '../assets/images/info-icon.svg'
import Box from './primitives/Box'
import {
  ComparisonTable,
  SpacerRow,
  TableCell,
  TableRow,
} from './ComparisonTable'
import { BenchmarkColumn, benchmarkExplanations } from '../pages/benchmarks'
import Tooltip from './Tooltip'
import Stack from './primitives/Stack'

export function BenchmarksTable({
  benchmarks,
}: {
  benchmarks: BenchmarkColumn[]
}) {
  const [selectedColumn, setSelectedColumn] = useState<string>(
    benchmarks[0].title,
  )
  const columnOptions = benchmarks.map(t => ({
    label: t.title,
    value: t.title,
  }))
  const selector = (
    <Box display={{ xs: 'block', lg: 'none' }} marginX={16}>
      <Select
        placeholder="Change plan"
        isSearchable={false}
        defaultValue={{ label: selectedColumn, value: selectedColumn }}
        options={columnOptions}
        onChange={option => (option ? setSelectedColumn(option.value) : null)}
      />
    </Box>
  )
  return (
    <>
      <Box
        font="h4"
        textAlign="center"
        color="black"
        display={{ xs: 'block', lg: 'none' }}
      >
        Change target:
      </Box>
      <ComparisonTable selector={selector}>
        <Box display={{ xs: 'none', lg: 'block' }}>
          <HeaderRow
            benchmarks={benchmarks}
            selectedBenchmark={selectedColumn}
          />
        </Box>
        <BenchmarkValueRows
          benchmarks={benchmarks}
          selectedBenchmark={selectedColumn}
        />
        <SpacerRow
          height={32}
          columns={benchmarks}
          selectedColumn={selectedColumn}
          firstColumnWidth="60%"
        />
      </ComparisonTable>
    </>
  )
}

function HeaderRow({
  benchmarks,
  selectedBenchmark,
}: {
  benchmarks: BenchmarkColumn[]
  selectedBenchmark: string
}) {
  return (
    <TableRow
      columns={benchmarks}
      selectedColumn={selectedBenchmark}
      variant="header"
    >
      <TableCell />
      {benchmarks.map(benchmark => (
        <TableCell key={benchmark.title}>
          <Box fontWeight={700}>{benchmark.title}</Box>
        </TableCell>
      ))}
    </TableRow>
  )
}

function BenchmarkValueRows({
  benchmarks,
  selectedBenchmark,
}: {
  benchmarks: BenchmarkColumn[]
  selectedBenchmark: string
}) {
  return (
    <>
      {benchmarkExplanations.map(explanation => {
        const rowVariant =
          'rowVariant' in explanation ? explanation.rowVariant : 'normal'
        const isNested =
          'isNested' in explanation ? explanation.isNested : false
        return (
          <TableRow
            key={explanation.title}
            columns={benchmarks}
            selectedColumn={selectedBenchmark}
            variant={rowVariant}
            firstColumnWidth="60%"
          >
            <TableCell>
              <Stack
                position="relative"
                width="100%"
                paddingLeft={isNested ? 12 : 0}
                paddingRight={{ xs: 0, xsm: 48, sm: 0 }}
                borderLeftStyle={isNested ? 'solid' : undefined}
                // @ts-ignore custom one-off border
                borderLeftWidth={4}
                borderLeftColor="blue100"
              >
                <Box font="h6" minWidth={{ xs: 100, md: 250 }}>
                  {explanation.title}
                  {'tooltip' in explanation ? (
                    <Box
                      display="inline-block"
                      position={{ xs: 'absolute', md: 'relative' }}
                      top={0}
                      right={{ xs: -48, xsm: 0 }}
                      // @ts-ignore custom negative pull towards text
                      marginLeft={{ xs: 0, md: -8 }}
                    >
                      <Tooltip title={explanation.tooltip}>
                        <Box.img src={infoIcon} />
                      </Tooltip>
                    </Box>
                  ) : null}
                </Box>
                <Box
                  font="small"
                  color="black500"
                  marginTop={{ xs: 10, md: 8 }}
                >
                  {explanation.description}
                </Box>
              </Stack>
            </TableCell>
            {benchmarks.map(benchmark => {
              return (
                <TableCell key={`${explanation.title}${benchmark.title}`}>
                  <Box font="small">{benchmark.values[explanation.key]}</Box>
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}
